import { useState, useEffect, useRef } from 'react'
import { Colors, Fonts } from '../../styles'
import { Button, Select, Toast, Alert } from '../../components'
import { getCompanyList_2 } from '../../service/api'
import CommonIcons from '../../assets/commonIcons'
import UploadFile from './upload-file'
import './index.css'
import {DailyIncomeService} from '../../service'
import { Function } from '../../utils'

let fileData = null;

// This is temp controller; use it to cancel API requests
const controller = new AbortController()

export default function DailyIncome() {
	const [listOfCompanies, setListOfCompanies] = useState([])
  const [fileValidated, setFileValidation] = useState(false)
	const [uploadedCSV, setUploadedCSV] = useState(false)
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState({ name: null, value: null })
	const [uploadProgress, setUploadProgress] = useState(0)
	const [error, setError] = useState(false)
	const [errMsg, setErrMsg] = useState("")

	const onUploadProgress = (progressEvent) => {
		let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
		console.log("onUploadProgress", percentCompleted, "progressEvent", progressEvent)
		setUploadProgress(percentCompleted)
	}

  const onSelectCompany = (event) => {
		setCompany({ name: event.target.value, value: event.target.value })
	}
	useEffect(() => {
		getAllCompanies()
	}, [])
	const getAllCompanies = () => {
		getCompanyList_2((res) => {
			if (res && res.status) {
				setListOfCompanies(
					res.data.map((_item) => ({ name: _item.tradeName, value: _item.id }))
				)
			}
		})
	}
  const uploadCSVFile = async () => {
    if (!company.value) {
      alert("Company not selected. Please select a company first.")
      return;
    }
    setLoading(true)
		setError(false)
    const formData = new FormData()
    formData.append('file', fileData)
    formData.append('orgId', company.value)
    DailyIncomeService.uploadIncomeFile(formData, controller.signal, onUploadProgress, (result) => {
			console.log('uploadCSVFile', result);
			setLoading(false)
			if (result.status) {
				setUploadedCSV(true)
				setTimeout(() => { setUploadedCSV(false) }, 5000)
				setUploadProgress(0)
			} else {
				setUploadedCSV(false)
				setError(true)
				setErrMsg(result.message)
			}
			setFileValidation(false)
			fileData = null;
		})
  }
	return (
		<div style={{ marginBottom: 50 }}>
			<div style={styles.title}>Daily Income</div>
			<SelectCompany
        listOfCompanies={listOfCompanies}
        onSelectCompany={onSelectCompany}
        company={company} />
			<UploadFile
				abortUpload={() => controller ? controller.abort() : null}
				uploadProgress={uploadProgress}
        companyId={company.value}
				uploadedCSV={uploadedCSV}
        updateFileValidationStatus={(_file = null) => {
          if (_file) {
            setFileValidation(true)
            fileData = _file
          } else {
						setFileValidation(false)
					}
        }}
      />
			<Button
				text={'Process Details'}
        disabled={!fileValidated}
        loading={loading}
				style={{
          background: !fileValidated ? Colors.primaryColor02 : Colors.primaryColor01,
          color: Colors.white,
          margin: '24px 43%',
          height: 48,
					width: 183,
          borderRadius: 8,
				}}
        onClick={uploadCSVFile}
			/>
			<Alert
				open={error}
				message={errMsg}
				callback={() => setError(false)}
			/>
		</div>
	)
}

const styles = {
	title: {
		fontSize: 24,
		margin: '20px 0',
		textAlign: 'center',
	},
}

export const Container = ({ children }) => (
	<div
		className="full-container"
		style={{
			padding: '24px 30px 24px',
			border: `1px solid ${Colors.typographyColor05}`,
			borderRadius: 10,
			marginTop: 24,
			background: Colors.white,
		}}
	>
		{children}
	</div>
)

export const Separator = () => (
	<hr
		style={{
			border: `1px solid ${Colors.typographyColor05}`,
			margin: 0,
			marginTop: 16,
		}}
	/>
)

export const TextLabel = ({ children }) => (
	<span
		style={{
			fontSize: Fonts.size.small,
			fontWeight: Fonts.weight.regular,
		}}
	>
		{children}
	</span>
)

const ContainerRow = ({ children }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				marginTop: 24,
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			{children}
		</div>
	)
}

const ContainerField = ({ children }) => {
	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column'
		}}>
			{children}
		</div>
	)
}

const SelectCompany = ({ listOfCompanies, onSelectCompany, company }) => {
  const [errorMessage, setErrorMessage] = useState("Unknown error occured")
  const [hasError, setError] = useState(false)
	const [notifyFileDownload, setNotifyFileDownload] = useState(false)

  const falsifyError = () => {
    setError(false)
  }
  const downloadSampleFile = () => {
    if (company.value) {
      try {
        DailyIncomeService.downloadIncomeSampleCSV(company.value)
        .then(result => {
          if (result.status) {
            Function.downloadCSV(result.data, 'sample-format')
						setNotifyFileDownload(true)
          } else if ('errorCode' in result) {
            setError(true)
            setErrorMessage(result.message)
          } else {
            alert("Unknown error occured")
          }
        }).catch(error => console.log(error))
      } catch (error) {
        console.log('downloadSampleFile', error)
      }
    }
  }
	return (
		<Container>
			<div
				style={{
					fontSize: Fonts.size.medium,
					fontWeight: Fonts.weight.medium,
				}}
			>
				Select Company
			</div>
			<Separator />
			<ContainerRow>
				<ContainerField>
					<TextLabel>Select Company</TextLabel>
					<Select
						id={'select-company'}
						options={listOfCompanies}
						displayEmpty
						style={{
							height: 56,
							padding: '0',
							marginTop: 8,
						}}
						placeholder="Select Company"
						value={company.value}
						handleChange={onSelectCompany}
					/>
				</ContainerField>
				<ContainerField>
					<DownloadFile disabled={!company.value} onDownloadClick={downloadSampleFile} />
				</ContainerField>
			</ContainerRow>
      <Alert
        open={hasError}
        message={errorMessage}
        callback={falsifyError}
      />
			<Toast
				isOpen={notifyFileDownload}
				severity="success"
				message={"File downloaded successfully"}
				onClose={() => setNotifyFileDownload(false)}
			/>
		</Container>
	)
}

const DownloadFile = ({ disabled = false, onDownloadClick }) => (
	<>
		<TextLabel>Sample format</TextLabel>
		<div
			id="download-file"
			onClick={onDownloadClick}
			style={{
				border: `1px dashed ${Colors.typographyColor04}`,
				borderRadius: 4,
				padding: '18px 20px',
				display: 'flex',
				flexDirection: 'row',
				marginTop: 8,
				cursor: 'pointer',
				background: disabled && 'rgba(242, 242, 242, 1)',
			}}
		>
			<img
				src={
					disabled
						? CommonIcons.DocumentDisabledIcon
						: CommonIcons.DocumentIcon
				}
				style={{ height: 20, width: 20 }}
			/>
			<span
				style={{
					marginLeft: 20,
					color: disabled && Colors.typographyColor04,
				}}
			>
				Download
			</span>
			<img
				src={
					disabled
						? CommonIcons.DownloadDisabledIcon
						: CommonIcons.DownloadIcon
				}
				style={{ height: 20, width: 20, marginLeft: 'auto' }}
			/>
		</div>
	</>
)
