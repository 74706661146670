import React from "react";
import CustomTable from "../../components/table";
import { observer } from "mobx-react";
import { makeFilters, makeSort, convertTimestamptoDate, debounce } from "../../utils/helper";
import PaginationStore from "../../stores/pagination";
import Alert from "../../components/alert";
import EWAStore from "../../stores/ewaStore";
import { EWAService } from "../../service";
import { getSearch } from "../../service/api";
import SearchField from "../../components/searchField";
import FilterBar from "../../components/filterBar";
import { SEARCH_TYPE_CODES } from "../../common/constants";
import { getLocalStorage } from "../../utils/localStorage";
import { DATA } from './data';

class EWA extends React.Component {
  constructor (props) {
    super(props);
    this.store = EWAStore;
    this.pagination = PaginationStore;
    this.dbounceCaller = debounce(() => this.fetchSearch(true));
    this.state = {
      searchText: "",
      searchType: "creditLineId",
      filter: {},
      loading: true,
      hasError: false,
      errorMessage: "",
      sort: {
        sortType: false,
        sort: false
      },
      pageNumberValue: "1",
      pageButton: true,
    }
  }

  componentDidMount () {
    this.fetchEWARequest(true);
  }

  fetchEWARequest ($hasUpdate = false) {
    const { loading, filter, sort, searchText, searchType } = this.state;
    const { page, pageSize } = this.pagination;

    let params = {
      page: page,
      pageSize: pageSize
    }

    if ($hasUpdate) {
      params = {
        page: 1,
        pageSize: pageSize
      }
    }

    const updatedFilter = makeFilters(filter, "ewa")

    if (sort.sort) {
      Object.assign(params, sort);
    }

    Object.assign(params, { filter: updatedFilter.filter });

    if (searchText) {
      Object.assign(params, { "searchText": searchText, "searchType": searchType });
    }

    if (!loading) {
      this.setState({ loading: true });
    }

    EWAService.getEWAApplicationListing(params, (res) => {
      if (res && res.success) {
        const { page, totalCount } = res.response;
        this.store.setData(res.response.ewaDtoList);
        this.pagination.goToPage(page);
        this.pagination.updateMaxPage(totalCount);
        this.setState({ loading: false });
      } else {
        this.setState({
          hasError: true,
          loading: false
        })
      }
    })
  }

    handlePageChange = (evt, value) => {
      this.pagination.goToPage(value);
      this.setState({
        pageNumberValue: value
      })
      this.fetchEWARequest();
    }

    handleGoToPageChange = () => {
      const { pageNumberValue } = this.state;
      this.pagination.goToPage(pageNumberValue && pageNumberValue > 0 ? pageNumberValue : 1);
      if (pageNumberValue && pageNumberValue <= 0) {
        this.setState({
          pageNumberValue: 1
        })
      }
      this.fetchEWARequest();
    }

    onChangeGoToValue = (evt) => {
      this.setState({
        pageNumberValue: evt.target.value
      })
    }


    falsifyError = () => {
      this.setState({
        hasError: false
      })
    }

    // search methods
    fetchSearch = () => {
      const { searchText, searchType } = this.state;

      const params = {};

      if (searchText) {
        this.setState({
          pageButton: false
        })
        Object.assign(params, { "searchText": searchText, "searchType": searchType, "type": "ewa" });

        getSearch(
          params,
          (res) => {
            if (res.success && res) {
              this.store.setData(res.response.ewaDtoList);
              this.setState({ loading: false });
            } else {
              this.setState({
                hasError: true,
                loading: false
              })
            }
          })
      } else {
        this.fetchEWARequest(true);
        this.setState({
          pageButton: true
        })
      }
    }

    handleSearch = evt => {

      this.setState({ searchText: evt.target.value });

      this.dbounceCaller();
    }

    handleSearchType = (evt) => {
      this.setState({
        searchType: evt.target.value
      })
    }

    // sort methods
    handleSort = (sortByValue) => {
      const { sort } = this.state;
      let nSort = sort;

      if (!nSort.sort) {
        nSort = {
          "sortLabel": sortByValue,
          "sortType": "desc",
          "sort": sortByValue
        }
      } else {
        // switch sortType and sortBy in state
        if (nSort.sortLabel === sortByValue) {
          nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
        } else {
          nSort = {
            "sort": sortByValue,
            "sortLabel": sortByValue,
            "sortType": "desc"
          }
        }
      }

      this.setState({
        sort: makeSort(nSort, "loan")
      }, () => this.fetchEWARequest(true))
    }

    // filter methods
    handleFilter = data => {
      let tempFilter = Object.assign({}, this.state.filter);
      data.forEach(item => {
        const propertyValue = { [item.property]: item.value };
        Object.assign(tempFilter, propertyValue);
      })

      if (!data.length) {
        tempFilter = {};
      }

      this.updateProperty(tempFilter);
    }

    updateProperty = (_filter) => {
      console.log("updating property...", _filter);
      this.setState({
        filter: _filter
      }, () => this.fetchEWARequest(true))
    }

    removeProperty = (property) => {
      let tempFilter = Object.assign({}, this.state.filter);
      delete tempFilter[property];
      this.updateProperty(tempFilter)
    }

    clearFilter = _ => {
      this.setState({
        filter: {}
      }, () => this.updateProperty())
    }

    handleColTitle = (items) => {
      const res = getLocalStorage("LmsUserDetails");
      const { role } = res;

      const checkPermission = (PERMISSIONS) => {
        let conditionCheck = false;
        for (let item of PERMISSIONS) {
          if (role.includes(item)) {
            conditionCheck = true;
            break;
          }
        }

        return conditionCheck;
      }

      if (checkPermission(["ROLE_CRM_ADMIN", "ROLE_KDR"])) {
        return items;
      } else {
        const newarr = items.filter((a) => { return a.key !== 'loan-reset-kyc-doc' });
        return newarr;
      }
      // if(checkPermission(["ROLE_KDR"])){

      // }
      // else{
      //    const newarr = items.filter((a)=>{return a.key !== 'loan-cancel-loan' && a.key !== 'loan-reset-kyc-doc'});
      //    return newarr;
      // }
    }



    render() {
      const { loading, hasError, errorMessage, sort, filter, pageNumberValue, pageButton } = this.state;
      const { data } = this.store;
      const { maxPage, page } = this.pagination;

      return (
        <>
          <SearchField
            placeholder={"Applications"}
            onSearch={this.handleSearch}
            onSearchType={this.handleSearchType}
            search={this.state.searchText}
            searchType={SEARCH_TYPE_CODES["ewa"]}
          />

          <FilterBar
            screen={"ewa"}
            appliedFilter={filter}
            callback={{
              handleFilter: this.handleFilter,
              removeProperty: this.removeProperty,
              clearFitler: this.clearFilter
            }}
          />

          <CustomTable
            {...this.props}
            cols={this.handleColTitle(DATA)}
            data={data}
            option={[]}
            loading={loading}
            count={maxPage}
            page={page}
            sort={sort}
            handlePageChange={this.handlePageChange}
            handleSort={this.handleSort}
            pageNumberValue={pageNumberValue}
            handleGoToPageChange={this.handleGoToPageChange}
            onChangeGoToValue={this.onChangeGoToValue}
            pageButton={pageButton}
          />
          <Alert
            open={hasError}
            message={errorMessage}
            callback={this.falsifyError}
          />
        </>
      );
    }
  }


export default observer (EWA);