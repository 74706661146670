import {
  PATHS,
  API,
  AXIOS_POST,
} from "./constants";

// export function validateDailyIncome (orgId = null, formData = null) {
//   if (orgId && formData) {
//     return new Promise((resolve, reject) => {
//       API.POST(PATHS.EWA.DAILY_INCOME_VALIDATE(), formData, {},
//         function (res) {
//           resolve(res);
//           console.log("validateDailyIncome", res);
//         }
//       )
//     })
//   }
// }

export function downloadIncomeSampleCSV (orgId = null) {
  if (orgId) {
    return new Promise((resolve, reject) => {
      API.GET(PATHS.EWA.DAILY_INCOME_DOWNLOAD_SAMPLE(orgId), {}, {},
        function (res) {
          resolve(res)
          console.log("downloadIncomeSampleCSV", res);
        }
      )
    })
  }
}

export function getIncomeSummary () {
  return new Promise((resolve, reject) => {
    API.GET(PATHS.EWA.DAILY_INCOME_SUMMARY(), {}, {},
      function (res) {
        resolve(res)
        console.log("getIncomeSummary", res);
      }
    )
  })
}

export function uploadIncomeFile(formData, signal = null, uploadProgressTracker, callback) {
    const config = {
      signal,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: uploadProgressTracker,
    }
    AXIOS_POST(PATHS.EWA.DAILY_INCOME_FILE_UPLOAD(), formData, config, callback)
}

export function dailyIncomeFileValidate (formData, cb) {
  const config = {
      headers: { "Content-Type": "multipart/form-data" },
  }
  AXIOS_POST(PATHS.EWA.DAILY_INCOME_VALIDATE(), formData, config, cb)
}

export default {
  downloadIncomeSampleCSV,
  getIncomeSummary,
  uploadIncomeFile,
  dailyIncomeFileValidate
}