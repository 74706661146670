import dayjs from "dayjs";
import prettyBytes from 'pretty-bytes';

function ConvertTimestampToDate(input) {
  return dayjs(input).format('DD/MM/YYYY')
}

const downloadCSV = (base64Data, fileName = "file.csv") => {
  // Decode Base64
  const decodedData = atob(base64Data);

  // Convert to Blob
  const blob = new Blob([decodedData], { type: "text/csv" });

  // Create a download link
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  // Append to the body and trigger click
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const base64StringToFile = (source, fileName = `daily_income_file_${Date.now()}.csv`) => {
  return new File([
    new Blob([source])
  ], fileName);
}

const convertBytesToMB = (bytes) => {
  if (!bytes) return;
  return prettyBytes(bytes)
}

export default {
  ConvertTimestampToDate,
  downloadCSV,
  base64StringToFile,
  convertBytesToMB,
}