import { useState, useEffect, useRef } from 'react'
import { Colors, Fonts } from '../../styles'
import CommonIcons from '../../assets/commonIcons'
import { Function } from '../../utils';

export default function UploadComplete({ fileData, uploadComplete }) {
  const { size, name } = fileData;
  if (uploadComplete) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              fontSize: 12,
              fontWeight: '600',
              marginTop: 10,
            }}
          >
            Upload complete
          </div>
          <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 15,
        }}>
          <img src={CommonIcons.ExcelFileIcon} style={{ height: 24, width: 24, objectFit: 'contain' }} />
          <div style={{ marginLeft: 8, fontSize: 12, fontWeight: Fonts.weight.medium }}>{name}
            <span style={{
              color: Colors.typographyColor02
            }}> | {Function.convertBytesToMB(size)}</span>
            </div>
          <img src={CommonIcons.CheckCircleIcon} style={{ height: 24, width: 24, objectFit: 'contain', marginLeft: 'auto' }} />
        </div>
        </div>
      </div>
    )
  }
  return null;
}
