import CopyIcon from './copy.png'
import SmsIcon from './sms.png'
import WAIcon from './wa.png'
import SearchIcon from './search.png'
import DataNotFound from './data-not-found.png'
import CopyIconDisabled from './copy-disabled.png'
import SmsIconDisabled from './sms-disabled.png'
import WAIconDisabled from './wa-disabled.png'
import DownloadDisabledIcon from './download-disabled.png'
import DownloadIcon from './download.png'
import DocumentDisabledIcon from './document-disabled.png'
import DocumentIcon from './document.png'
import CheckCircleIcon from './check-circle.png'
import ExcelFileIcon from './excel-file.png'


export default {
  CopyIcon,
  SmsIcon,
  WAIcon,
  SearchIcon,
  DataNotFound,
  CopyIconDisabled,
  SmsIconDisabled,
  WAIconDisabled,
  DownloadDisabledIcon,
  DownloadIcon,
  DocumentDisabledIcon,
  DocumentIcon,
  CheckCircleIcon,
  ExcelFileIcon,
}