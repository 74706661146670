import React from "react";
import Detail from "./../../components/detail";
import { findByPathName } from "./../../utils/helper";
import { EWAService } from "./../../service";
import { convertTimestamptoDate } from "./../../utils/helper";
import { rolePermission } from "../../common/constants";
import { getLocalStorage } from "../../utils/localStorage";

// function UpdatePaymentButton (props) {
//     const { loanId } = props;
//     const [loading, setLoading] = React.useState(false);
//     const [open, setSnackbar] = React.useState(false);
//     const [message, setMessage] = React.useState(false);
//     const handleCallback = (res) => {
//         if (res.success) {
//             setMessage('Payment records updated successfully');
//         } else {
//             setMessage(res.message ?? 'some error occured');
//         }
//         setLoading(false);
//         setSnackbar(true);
//     }

//     return (
//         <React.Fragment>
//             <Button
//                 variant="contained"
//                 color="default"
//                 disabled={loading}
//                 onClick={() => {
//                     setLoading(true);
//                     updatePaymentRecord(loanId, {}, handleCallback)
//                 }}
//                 style={{
//                     backgroundColor: loading ? 'grey' : "rgb(16,12,109)",
//                     color: "white",
//                     borderRadius: 5,
//                 }}
//             >UPDATE</Button>
//             <Snackbar
//                 open={open}
//                 autoHideDuration={3000}
//                 onClose={() => setSnackbar(false)}
//                 message={message}
//                 action={null}
//             />
//         </React.Fragment>
//     )
// }


class EWADetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            data: [],
            userDetail: {},
            applicationDetail: {},
            lineDetail: {},
            bankDetail: {},
            digilockerDetail: {},
            kycDetail: {},
            statementDetail: {},
            transactionDetail: {},
            currentCycleDetail: {},
            withdrawalDetail: {},
            paymentDetail: {}
        }
    }

    componentDidMount () {
        const { location } = this.props;
        const applicationId = findByPathName(location);
        this.callApis(applicationId);
    }


    callApis = (id) => {

        const getEWAUserDetailPromise = new Promise(function (resolve, reject) {
            EWAService.getEWAUserDetail(id, res => {
                if (res.success) {
                    resolve(res.response.ewaUserDetail)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        const getEWALineDetailPromise = new Promise(function (resolve, reject) {
            EWAService.getEWALineDetail(id, res => {
                if (res.success) {
                    resolve(res.response.ewaLineDetails)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        const getEWAApplicationDetail = new Promise(function (resolve, reject) {
            EWAService.getEWAApplicationDetail(id, res => {
                if (res.success) {
                    resolve(res.response.applicationDetails)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        const getEWABankDetailPromise = new Promise(function (resolve, reject) {
            EWAService.getEWABankDetail(id, res => {
                if (res.success) {
                    resolve(res.response.bankDetails)
                } else {
                    console.log('rula')
                    reject(res.message ?? '')
                }
            })
        })

        const getEWADigilockerDetailPromise = new Promise(function (resolve, reject) {
            EWAService.getEWADigilockerDetail(id, res => {
                if (res.success) {
                    resolve(res.response.digilockerDetails)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        const getEWAUserKycPromise = new Promise(function (resolve, reject) {
            EWAService.getEWAUserKyc(id, res => {
                if (res.success) {
                    resolve(res.response.ewaKycDetail)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        const getEWATransactionPromise = new Promise(function (resolve, reject) {
            EWAService.getEWATransactionDetail(id, res => {
                if (res.success) {
                    resolve(res.response.ewaKycDetail)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        const getEWACurrentCycleDetailPromise = new Promise(function (resolve, reject) {
            EWAService.getEWACurrentCycleDetail(id, res => {
                if (res.success) {
                    resolve(res.response.currentCycleDetails)
                } else {
                    reject(res.message ?? '')
                }
            })
        })

        Promise.all([
            getEWAUserDetailPromise,
            getEWAApplicationDetail,
            getEWALineDetailPromise,
            getEWABankDetailPromise,
            getEWADigilockerDetailPromise,
            getEWAUserKycPromise,
            getEWACurrentCycleDetailPromise,
            // getEWATransactionPromise,
        ]).then(res => {
            this.setState({
                userDetail: res[0],
                applicationDetail: res[1],
                lineDetail: res[2],
                bankDetail: res[3],
                digilockerDetail: res[4],
                kycDetail: res[5],
                currentCycleDetail: res[6],
                id: id
            }, () => this.makeColumns());
        }).catch(err => {
            console.log('error', err);
            this.setState({
                hasError: true,
                loading: false,
                id: id
            })
        })
    }

    createEmandateDetail = (dat) => {

        if (!dat) return [];

        const { createdDate, completedDate, status, emandateBankName, emandateAccountNumber, emandateIfscCode, mode, vpa } = dat;
        return [
            { key: "emandate-created-date", label: "CREATED DATE", value: convertTimestamptoDate(createdDate) },
            { key: "emandate-completed-date", label: "COMPLETED DATE", value: convertTimestamptoDate(completedDate) },
            { key: "emandate-status", label: "STATUS", value: status },
            { key: "emandate-bank-name", label: "BANK NAME", value: emandateBankName },
            { key: "emandate-acc-number", label: "ACCOUNT NUMBER", value: emandateAccountNumber },
            { key: "emandate-ifsc-code", label: "IFSC CODE", value: emandateIfscCode },
            { key: "emandate-mode", label: "PAYMENT METHOD", value: mode ?? "-" },
            { key: "emandate-vpa", label: "VPA", value: vpa ?? "-" }
        ]
    }

    createBankDetail = (dat) => {

        if (!dat) return [];

        const { accountNumber, ifscCode, accountHolderName,
            bankDetailCompletedate
        } = dat;
        return [
            { key: "acc-holder-name", label: "ACCOUNT HOLDER NAME", value: accountHolderName },
            { key: "account-number", label: "ACCOUNT NUMBER", value: accountNumber },
            { key: "ifsc-code", label: "IFSC CODE", value: ifscCode },
            { key: "bank-detail-complete-date", label: "BANK DETAILS COMPLETE DATE", value: convertTimestamptoDate(bankDetailCompletedate) },
            // { key: "bank-detail", label: "BANK DETAIL", value: accountNumber ? "Yes" : "No" },
        ]
    }

    createDeductionDetail = (dat) => {
        if (!dat) return [];

        const { deductionRequestRaised, requestDate, deductionRequestedAmount } = dat;
        return [
            { key: "deduction-request-raised", label: "DEDUCTION REQUEST RAISED", value: deductionRequestRaised ? "YES" : "NO" },
            { key: "deduction-request-amount", label: "DEDUCTION REQUEST AMOUNT", value: deductionRequestedAmount ?? "-" },
            // { key: "emi-deducted", label: "EMI DEDUCTED", value: emiDeducted??"-" },
            // { key: "emi-uploaded", label: "EMI UPLOADED", value: emiUploaded??"-"},
            // { key: "processed-date", label: "PROCESSED DATE", value: convertTimestamptoDate(processedDate) },
            { key: "request-date", label: "REQUEST DATE", value: convertTimestamptoDate(requestDate) },
        ]
    }

    createCycleDetail = (dat) => {

        if (!dat) return [];

        const {
            startDate,
            endDate,
            status,
            currentLimit,
            lastUpdatedOn,
            utilizedWithdrawalCount,
            dueAmount,
            dueDate,
            withdrawalFee
        } = dat;
        return [
            { key: "start-date", label: "START DATE", value: startDate ? convertTimestamptoDate(startDate) : '-' },
            { key: "end-date", label: "END DATE", value: endDate ? convertTimestamptoDate(endDate) : '-' },
            { key: "current-cycle-status", label: "STATUS", value: status ?? "-" },
            { key: "current-cycle-limit", label: "CURRENT CYCLE LIMIT", value: currentLimit ?? "-" },
            { key: "cycle-last-update-on", label: "LAST LIMIT UPDATED ON", value: lastUpdatedOn ? convertTimestamptoDate(lastUpdatedOn) : "-" },
            { key: "cycle-no-of-withdrawals", label: "NO OF WITHDRAWALS", value: utilizedWithdrawalCount ?? "-" },
            { key: "cycle-outstanding-amount", label: "OUTSTANDING AMOUNT", value: dueAmount ?? "-" },
            { key: "cycle-due-date", label: "CYCLE DUE DATE", value: dueDate ? convertTimestamptoDate(dueDate) : "-" },
            { key: "cycle-total-withdrawal-fees", label: "TOTAL WITHDRAWAL FEES", value: withdrawalFee ?? '-' }
        ]
    }

    createLineDetail = (dat) => {

        if (!dat) return [];

        const {
            lineStatus,
            outstandingAmount,
            dpdCount,
            lender,
            latestIncomeUpdatedOn,
            latestDASFileGeneratedOn,
            latestRepaymentAmount,
            latestRepaymentMode,
            latestRepaymentOn
        } = dat;
        return [
            { key: "line-status", label: "LINE STATUS", value: lineStatus ?? "-" },
            { key: "outstanding-amount", label: "OUTSTANDING AMOUNT", value: outstandingAmount ?? '-' },
            { key: "dpd-count", label: "DPD COUNT", value: dpdCount ?? '-' },
            { key: "lender", label: "LENDER", value: lender ?? '-' },
            { key: "income-updated-date", label: "LATEST INCOME UPDATED ON", value: latestIncomeUpdatedOn ? convertTimestamptoDate(latestIncomeUpdatedOn) : '-' },
            { key: "latest-das-generated-on", label: "LATEST DAS FILE GENERATED ON", value: latestDASFileGeneratedOn ? convertTimestamptoDate(latestDASFileGeneratedOn) : '-' },
            { key: "latest-repayment-amount", label: "LATEST REPAYMENT AMOUNT", value: latestRepaymentAmount ?? '-' },
            { key: "latest-payment-mode", label: "LATEST PAYMENT MODE", value: latestRepaymentMode ?? '-' },
            { key: "latest-payment-on", label: "LATEST PAYMENT ON", value: latestRepaymentOn ? convertTimestamptoDate(latestRepaymentOn) : '-' },
        ]
    }



    createApplicationDetail = (dat) => {

        if (!dat) return [];

        const {
            applicationId,
            lineId,
            applicationStatus,
            maxEligibleAmount,
            lender,
            collectionMode,
            cycleTenure,
            applicationCreationDate,
            kycCompleteOn,

        } = dat;
        return [
            { key: "ewa-application-id", label: "APPLICATION ID", value: applicationId },
            { key: "bp-line-id", label: "CREDIT LINE ID", value: lineId },
            { key: "ewa-application-status", label: "APPLICATION STATUS", value: applicationStatus },
            { key: "max-eligible-amount", label: "MAX ELIGIBLE AMOUNT", value: maxEligibleAmount },
            { key: "ewa-lender", label: "LENDER", value: lender },
            { key: "ewa-collection-mode", label: "COLLECTION MODE", value: collectionMode },
            { key: "ewa-cycle-tenure", label: "CYCLE TENURE", value: cycleTenure },
            { key: "application-creation-date", label: "APPLICATION CREATION DATE", value: applicationCreationDate ? convertTimestamptoDate(applicationCreationDate) : '-' },
            { key: "kyc-completed-on", label: "KYC COMPLETED ON", value: kycCompleteOn ? convertTimestamptoDate(kycCompleteOn) : '-' },
        ]
    }

    createLoanUpiAutoPayDetail = (dat) => {

        if (!dat) return [];

        const { allowAutoPay, mandateActive
        } = dat;
        return [
            { key: "l-upi-autopay-allow", label: "IS UPI AUTOPAY ALLOW", value: allowAutoPay ? "YES" : "NO" },
            { key: "l-mandate-allow", label: "IS MANDATE ACTIVE", value: mandateActive ? "YES" : "NO" },

        ]
    }

    createUserDetail = (dat) => {

        if (!dat) return [];

        const { phone, company, name, alternatePhone, userId, dob, gender, addressLine1,
            pinCode,
            city,
            maritalStatus } = dat;
        return [
            { key: "name", label: "NAME", value: name },
            { key: "phone", label: "PHONE", value: phone },
            { key: "company", label: "COMPANY", value: company },
            { key: "alternatePhone", label: "ALTERNATE PHONE", value: alternatePhone },
            { key: "e-dob", label: "DOB", value: dob ? convertTimestamptoDate(dob) : '-' },
            { key: "l-userId", label: "USER ID", value: userId },
            { key: "gender", label: "GENDER", value: gender },
            { key: "address-line-1", label: "ADDRESS", value: addressLine1 },
            { key: "city", label: "CITY", value: city },
            { key: "pincode", label: "PINCODE", value: pinCode },
            { key: "marital-status", label: "MARITAL STATUS", value: maritalStatus },
        ]
    }

    createKycDetail = (dat) => {
        if (!dat) return [];
        const { kycType, ckycEnabled, dobOnAadhaar, ckycStatus, aadhaarFromDigiLocker } = dat;
        return [
            { key: 'kyc-type', label: "KYC TYPE", value: kycType },
            { key: "ckyc-enabled", label: "CKYC ENABLED", value: ckycEnabled ? "Yes" : "No" },
            { key: "dobOnAadhaar", label: "DOB ON AADHAAR", value: dobOnAadhaar ? convertTimestamptoDate(dobOnAadhaar) : "-" },
            { key: "ckycStatus", label: "CKYC STATUS", value: ckycStatus ?? "-" },
            { key: "aadhaarFromDigilocker", label: "AADHAAR FROM DIGILOCKER", value: <div style={{ cursor: 'pointer' }} onClick={() => window.open(aadhaarFromDigiLocker, '_blank')}><embed src={aadhaarFromDigiLocker} type="application/pdf" width={200} height={200} style={{ pointerEvents: 'none' }} /></div>},
        ]
    }

    createImageDocumentDetail = (dat) => {
        if (!dat) return [];
        const { photo } = dat;
        return [
            { key: "photo", label: "PHOTO", value: photo },
        ]
    }

    createDigiLockerDetail = (dat) => {
        if (!dat) return [];
        const { nameOnPan, pan, aadhar, dob } = dat;
        return [
            { key: "digi-name-on-pan", label: "NAME ON PAN", value: nameOnPan },
            { key: "digi-pan", label: "PAN", value: pan },
            { key: "digi-aadhar", label: "AADHAAR", value: aadhar ?? '-' },
            { key: "digi-dob", label: "DOB", value: dob ? convertTimestamptoDate(dob) : '-' }
        ]
    }


    createDigilockerAadhaarDetail = (dat) => {
        if (!dat) return [];
        const { imagePath } = dat;
        return [
            { key: "loan-digilocker-aadhaar-detail", label: "", value: imagePath }
        ]
    }

    createLoanCkycDetail = (dat) => {
        if (!dat) return [];
        const { ckycDocumentUrl } = dat;
        return [
            { key: "loan-ckyc-document-detail", label: "", value: ckycDocumentUrl }
        ]
    }


    createPaymentDetail = () => {
        return [
            { key: "ewa-payment-detail", label: "PAYMENT", value: 'View', fn: (v) => v },
            { key: "ewa-statement-detail", label: "STATEMENT", value: 'View', fn: (v) => v },
            { key: "ewa-withdrawal-detail", label: "WITHDRAWAL", value: 'View', fn: (v) => v },
            // { key: "ewa-transaction-detail", label: "TRANSACTION", value: 'View', fn: (v) => v },
        ]
    }

    generatePaymentLink = () => {
        const { role } = getLocalStorage("LmsUserDetails");
        const { lineStatus } = this.state.lineDetail;


        const checkPermission = (PERMISSIONS) => {
            let conditionCheck = false;
            for (let item of PERMISSIONS) {
                if (role.includes(item)) {
                    conditionCheck = true;
                    break;
                }
            }

            return conditionCheck;
        }

        if (checkPermission(["ROLE_ADMIN", "ROLE_CRM_ADMIN", "ROLE_CE", "ROLE_ADMIN_RO"])) {
            return [
                // { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
                { key: "promise-to-pay-ewa", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v }
            ]
            //     if (lineStatus === "FREEZE") {
            //         return [
            //             // { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
            //             { key: "promise-to-pay-ewa", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v },
            //             // { key: "raise-loan-settlement", label: "RAISE LOAN SETTLEMENT", value: 'Raise', fn: (v) => v }
            //         ]
            //     } else {
            //         return [
            //             // { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
            //             { key: "promise-to-pay-ewa", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v }
            //         ]
            //     }
            // } else {
            //     return [
            //         // { key: "loan-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
            //     ]
            // }
        }
    }

    createEmandateCharges = () => {
        return [
            { key: "emandate-charge-history", label: "EMANDATE CHARGES", value: 'View', fn: (v) => v }
        ]
    }


    createLoanCancel = (cancelable, allowCancellation) => {
        const ar = [];
        if (cancelable) {
            ar.push({ key: "loan-ni-button", label: "MARK NOT INTERESTED", value: 'Not Interested', fn: (v) => v });
        } if (allowCancellation) {
            ar.push({ key: "loan-cancel-button", label: "LOAN CANCEL", value: 'Cancel', fn: (v) => v });
        }
        return ar;
    }

    checkViewPermission = (key) => {
        const role = "roleAdmin";
        return rolePermission[role].includes(key);
    }


    checkLoanCancelation = () => {
        const blocked = [
            "prashant.kumar@betterplace.co.in",
            "md.azad@betterplace.co.in",
            "chandan.debnath@betterplace.co.in",
            "deepak.jamwal@betterplace.co.in",
            "kajal.thakur@betterplace.co.in",
            "manikant.giri@betterplace.co.in",
            "neelam.saroj@betterplace.co.in",
            "priyanka.shrivastava@betterplace.co.in",
            "sumit.kumar1@betterplace.co.in",
            "roshan.sharma@betterplace.co.in",
            "shalini.lodhi@betterplace.co.in",
            "pratima.pandey@betterplace.co.in",
            "waseem.khan@betterplace.co.in",
            "pooja.saxena@betterplace.co.in",
            "nikita.jain@betterplace.co.in",
            "saisuma@betterplace.co.in",
            "saritha@betterplace.co.in",
            "shilpa.s@betterplace.co.in",
            "divya.t@betterplace.co.in"
        ]
        const res = getLocalStorage('LmsUserDetails');
        const { role, email } = res;
        return !blocked.includes(email);
        // for (let item of role) {
        //     if (["ROLE_LC", "ROLE_CRM_ADMIN", "ROLE_ADMIN_RO"].includes(item)) {
        //         hasTrue = true;
        //         break;
        //     }
        // }
        // return hasTrue;
    }


    makeColumns = () => {
        const {
            userDetail,
            applicationDetail,
            lineDetail,
            bankDetail,
            digilockerDetail,
            kycDetail,
            currentCycleDetail,
        } = this.state;
        const detailData = [
            { key: "user-detail", label: "USER DETAIL", value: this.createUserDetail(userDetail), viewPermission: true },
            { key: "ewa-kyc-detail", label: "KYC DOCUMENT", value: this.createImageDocumentDetail(kycDetail), viewPermission: true },
            { key: "kyc-detail", label: "KYC DETAIL", value: this.createKycDetail(kycDetail), viewPermission: true },
            { key: "ewa-digilocker-detail", label: "DIGILOCKER DETAIL", value: this.createDigiLockerDetail(digilockerDetail), viewPermission: true },
            { key: "bank-detail", label: "BANK DETAIL", value: this.createBankDetail(bankDetail), viewPermission: true },
            { key: "application-detail", label: "APPLICATION DETAIL", value: this.createApplicationDetail(applicationDetail), viewPermission: true },
            { key: "line-detail", label: "LINE DETAILS", value: this.createLineDetail(lineDetail), viewPermission: true },
            { key: "current-cycle-detail", label: "CURRENT CYCLE DETAILS", value: this.createCycleDetail(currentCycleDetail), viewPermission: true },
            { key: "view-payment-detail", label: "PAYMENT DETAIL", value: this.createPaymentDetail(), viewPermission: this.checkViewPermission("view-payment-detail") },
            // { key: "disbursement-detail", label: loanDisbursementDetails ? "DISBURSEMENT DETAIL" : "", value: this.createDisbursementDetail(loanDisbursementDetails), viewPermission: this.checkViewPermission("disbursement-detail") },
            // { key: "loan-deduction-detail", label: deductionDetails ? "DEDUCTION DETAIL" : "", value: this.createDeductionDetail(deductionDetails), viewPermission: true },
            // { key: "emandate-detail", label: loanEmandateDetails ? "EMANDATE DETAIL" : "", value: this.createEmandateDetail(loanEmandateDetails), viewPermission: this.checkViewPermission("emandate-detail") },
            // { key: "loan-upi-auto-pay-col", label: loanUpiAutoPayDetails ? "UPI AUTOPAY DETAIL" : "", value: this.createLoanUpiAutoPayDetail(loanUpiAutoPayDetails), viewPermission: true },
            // { key: "loan-kyc-detail", label: loanKycDetails ? "KYC DETAIL" : "", value: this.createKycDetail(loanKycDetails), viewPermission: this.checkViewPermission("loan-kyc-detail") },
            // { key: "loan-digilocker-aadhaar-col", label: loanDigiLockerDetails ? "DIGILOCKER AADHAAR" : "", value: this.createDigilockerAadhaarDetail(loanDigiLockerDetails), viewPermission: true },
            // { key: "loan-ckyc-document-col", label: loanCkycDetails ? "CKYC DETAILS" : "", value: this.createLoanCkycDetail(loanCkycDetails), viewPermission: true },
            // { key: "loan-emandate-charges", label: "EMANDATE CHARGES", value: this.createEmandateCharges(), viewPermission: this.checkViewPermission("loan-emandate-charges") },
            { key: "generate-payment-link", label: "GENERATE", value: this.generatePaymentLink(), viewPermission: this.checkViewPermission("generate-payment-link") },
        ]
        // if (cancelable || allowCancellation) detailData.push({ key: "cancel-ni-loan", label: "MARK LOAN AS", value: this.createLoanCancel(cancelable, allowCancellation), viewPermission: this.checkLoanCancelation("cancel-ni-loan") });
        this.setState({ data: detailData })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    render () {
        const { loading, data, id, applicationDetail } = this.state;
        const bankTransfer = null;
        return (
            <Detail
                data={data}
                loading={loading}
                handleBack={this.handleBack}
                id={id}
                creditlineId={applicationDetail.lineId}
                bankTransfer={bankTransfer}
            />
        );
    }
}

export default EWADetail;