import { css, keyframes } from '@emotion/css';
import { Colors } from '../../styles';

const loadAnim = keyframes`
	to {
		clip-path: inset(0 -1ch 0 0)
  	}
`;

export const BtnStyles = {
	primary_contained: {
		background: Colors.primaryColor01,
		'&:hover': {
			background: Colors.primaryColor01,
		}
	},
	primary_outlined: {
		borderColor: '#1b2d93',
		color: '#1b2d93',
		'&:hover': {
			borderColor: '#1b2d93',
			color: '#1b2d93'
		}
	},
	secondary_contained: {
		background: '#0000003b',
		color: 'black',
		'&:hover': {
			background: '#0000003b',
			color: 'black'
		}
	},
	secondary_outlined: {
		borderColor: '#0000003b',
		color: 'black',
		'&:hover': {
			borderColor: '#0000003b',
			color: 'black'
		}
	},
	loading: css({
		display: 'inline-block',
		fontFamily: 'monospace',
		clipPath: 'inset(0 3ch 0 0)',
		animation: `${loadAnim} 0.6s steps(4) infinite`
	})
};
