import React from "react";
import { convertTimestamptoDateWithTime, findByPathName } from "../../utils/helper";
import { Button, Grid, Typography } from "@material-ui/core";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getReviewEWADetails, postNameReviewLoanFlagStatus, postNameReviewLoanFlagCKYCStatus, getLoanKycInfo, postNameReviewEWA } from "../../service/api";
import Alert from "../../components/alert";

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import PublishIcon from '@material-ui/icons/Publish';

import CustomImage from "../../components/imageView";
import CustomModal from "../../components/modal";
import imageNotAvailable from '../../assets/imageNotAvailable.png';
import "./common.css";
import ActionDialogBoxWithComment from "../../components/dialogwithComment";
import { getLocalStorage } from "../../utils/localStorage";
class ManualCheckDetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            errorType: "",
            data: [],
            details: {},
            showButton: false,
            loaderForBox: false,
            loanId: "",
            modalOpen: false,
            modalChildren: null,
            commentValue: "",
            errors: {},
            isCkycEnabled: false
        }
    }

    componentDidMount () {
        const { location } = this.props;
        const id = findByPathName(location);
        this.callApi(id);
    }

    callApi = (id) => {

        getReviewEWADetails(id, (res) => {
            if (res && res.status) {
                const { reviewed, docLocation } = res.data;
                // getLoanKycInfo(res.response.loanId, {}, (kycResponse) => {
                //     const { isCkycEnabled } = kycResponse;
                //     console.log("ISKYCRESPONSE", isCkycEnabled);
                //     // const { isCkycEnabled } = kycResponse.loanKycDetail;
                //     this.setState({ isCkycEnabled });
                // })
                this.setState({
                    details: res.data,
                    loanId: res.data.loanId,
                    showButton: !reviewed && docLocation ? true : false,
                })

            } else {
                this.setState({
                    hasError: true,
                    errorType: "error",
                    errorMessage: res ? res.message : ""
                })
            }
        })

    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    dialogBoxHandleClose = () => {
        this.setState({
            actionDialogBoxIsOpen: false,
        })
    }


    handleActionResponse = (res) => {
        if (res && res.status) {
            this.setState({
                hasError: true,
                errorType: "success",
                errorMessage: res.message
            }, () => {
                setTimeout(() => {
                    this.dialogBoxHandleClose()
                    window.location.reload(false);
                    this.handleBack();
                }, 1200);
            })
        } else {
            this.setState({
                hasError: true,
                errorType: "error",
                errorMessage: res ? res.message : ""
            }, () => {
                setTimeout(() => {
                    this.dialogBoxHandleClose()
                }, 1200);
            })
        }
    }


    handleApprove = (loanId) => {

        const { commentValue } = this.state;
        let params = {
            "nameMatch": "Accept",
            "ewaApplicationId": loanId,
            "comment": commentValue
        }


        if (this.handleValidation()) {
            this.setState({
                loaderForBox: true
            })

            postNameReviewEWA(params, (res) => {
                this.handleActionResponse(res);
            })
        } else {
            this.setState({
                hasError: true,
                errorType: "error",
                errorMessage: "please add comment"
            })
        }



    }

    handleReject = (loanId) => {
        const { commentValue } = this.state;
        let params = {
            "nameMatch": "Reject",
            "ewaApplicationId": loanId,
            "comment": commentValue
        }


        if (this.handleValidation()) {
            this.setState({
                loaderForBox: true
            })

            postNameReviewEWA(params, (res) => {
                this.handleActionResponse(res);
            })

        } else {
            this.setState({
                hasError: true,
                errorType: "error",
                errorMessage: "please add comment"
            })
        }

    }

    handleReUpload = (loanId) => {
        const { commentValue } = this.state;
        let params = {
            "nameMatch": "Reupload",
            "ewaApplicationId": loanId,
            "comment": commentValue
        }

        if (this.handleValidation()) {
            this.setState({
                loaderForBox: true
            })

            postNameReviewEWA(params, (res) => {
                this.handleActionResponse(res);
            })

        } else {
            this.setState({
                hasError: true,
                errorType: "error",
                errorMessage: "please add comment"
            })
        }
    }

    handleChangeValue = event => this.setState({ commentValue: event.target.value });

    handleValidation = () => {
        const { commentValue } = this.state;
        let errorsTemp = {};
        let formIsValid = true;
        if (!commentValue) {
            formIsValid = false;
            errorsTemp["commentValue"] = "Cannot be empty";
        }
        this.setState({
            errors: errorsTemp
        })
        return formIsValid;
    }

    setDialogBox = (type) => {
        const { actionDialogBoxIsOpen, loaderForBox, loanId, commentValue, errors } = this.state;
        switch (type) {
            case "approve":
                return <ActionDialogBoxWithComment
                    title="Are sure to APPROVE this name check"
                    handleAgree={() => this.handleApprove(loanId)}
                    handleDisagree={() => this.dialogBoxHandleClose()}
                    description=""
                    actionDialogBoxIsOpen={actionDialogBoxIsOpen}
                    agreeName="APPROVE"
                    disagreeName="CLOSE"
                    loader={loaderForBox}
                    handleComment={this.handleChangeValue}
                    commentValue={commentValue}
                    errors={errors}
                />

            case "reject":
                return <ActionDialogBoxWithComment
                    title="Are you sure to REJECT this name check"
                    handleAgree={() => this.handleReject(loanId)}
                    handleDisagree={() => this.dialogBoxHandleClose()}
                    description=""
                    actionDialogBoxIsOpen={actionDialogBoxIsOpen}
                    agreeName="REJECT"
                    disagreeName="CLOSE"
                    loader={loaderForBox}
                    handleComment={this.handleChangeValue}
                    commentValue={commentValue}
                    errors={errors}
                />

            case "reUpload":
                return <ActionDialogBoxWithComment
                    title="Are you sure to send document for REUPLOAD"
                    handleAgree={() => this.handleReUpload(loanId)}
                    handleDisagree={() => this.dialogBoxHandleClose()}
                    description=""
                    actionDialogBoxIsOpen={actionDialogBoxIsOpen}
                    agreeName="REUPLOAD"
                    disagreeName="CLOSE"
                    loader={loaderForBox}
                    handleComment={this.handleChangeValue}
                    commentValue={commentValue}
                    errors={errors}
                />

                case "reset":
                    return <ActionDialogBoxWithComment
                        title="Are you sure to RESET the application details"
                        handleAgree={() => this.handleReset(loanId)}
                        handleDisagree={() => this.dialogBoxHandleClose()}
                        description=""
                        actionDialogBoxIsOpen={actionDialogBoxIsOpen}
                        agreeName="RESET"
                        disagreeName="CLOSE"
                        loader={loaderForBox}
                        handleComment={this.handleChangeValue}
                        commentValue={commentValue}
                        errors={errors}
                    />

            default:
                return ""
        }
    }

    dialogBoxHandleOpen = (type) => {
        this.setState({
            actionDialogBoxIsOpen: true,
            actionType: type
        })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleZoomChange = imageSrc => {
        const { isImageZoom } = this.state;
        if (isImageZoom === imageSrc)
            this.setState({
                isImageZoom: ""
            })
        else
            this.setState({
                isImageZoom: imageSrc
            })
    }

    handleImage = (item) => {
        this.setState({
            modalChildren: <CustomImage item={item} handleClose={this.handleModal} />
        }, () => {
            this.handleModal()
        })
    }

    handleReset = (loanId) => {
        const { commentValue } = this.state;
        let params = {
            "nameMatch": "Reset",
            "ewaApplicationId": loanId,
            "comment": commentValue
        }


        if (this.handleValidation()) {
            this.setState({
                loaderForBox: true
            })
            postNameReviewEWA(params, (res) => {
                this.handleActionResponse(res);
            })

        } else {
            this.setState({
                hasError: true,
                errorType: "error",
                errorMessage: "please add comment"
            })
        }

    }

    makeImageRow = (data) => {
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    {item.value.includes(".pdf") ? <>
                        <p> <a href={item.value}>View</a>.</p>
                    </> :
                        <>
                            {item.value && item.value !== "-" ?
                                <>
                                    <div onClick={() => { this.handleImage(item) }}>
                                        <img
                                            key={item.key}
                                            src={`${item.value}`}
                                            alt={`${item.label} _not_found`}
                                            style={{
                                                width: 250,
                                                height: 200,
                                                border: '2px solid',
                                                borderRadius: 6,
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                </> :
                                <>
                                    <img
                                        key={item.key}
                                        src={`${imageNotAvailable}`}
                                        alt={`${item.label} _not_found`}
                                        style={{
                                            width: 250,
                                            height: 200,
                                            border: '2px solid',
                                            borderRadius: 6,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </>}
                        </>
                    }
                </Grid>)}
            </Grid>
        )
    }


    showLess = (item) => {
        let tempStr = "";
        let tempLen = 0;
        if (item) {
            if (item.length < 25) { tempLen = item.length; }
            else if (item.length >= 25) { tempLen = 25; }
        }
        for (let i = 0; i < tempLen; i++) {
            if (item[i] === ",") { tempStr = tempStr + " , " }
            else { tempStr = tempStr + item[i] }
        }
        return <div title={item}>{tempStr}</div>;
    }

    makeRow = (data) => {
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    <Typography style={styles.fieldValue}>{item.key === "rw-comment" ? this.showLess(item.value) : item.value ?? "-"}</Typography>
                </Grid>
                )}
            </Grid>
        );
    }
    handleModal = () => {
        const { modalOpen } = this.state;
        if (!modalOpen) {
            this.setState({
                modalOpen: true
            })
        } else {
            this.setState({
                modalOpen: false
            })
        }
    };

    showDetails = () => {
        const { details, modalChildren, modalOpen } = this.state;
        const { bankAccountDedup, bankName, bankVerified, docLocation, ifsc, loanId, nameMatch, nameOnBank, nameOnPan,
            panDedup, bankAccountNumber, lastAction, reviewed, agent, nameVerificationDocUploadDate, comment, lastUpdated,
            lastActionDate, nameOnAadhaar, nameFromEmployer, dobAadhaar,
            livenessScore,
            dobUserEntered,
            faceMatchAadharSelfieScore,
            dobPan,
            empNamePhoneticScore,
            empNameTextScore,
            nameMatchResult,
            bankNameTextScore,
            finalResult,
            bankNamePhoneticScore,
            dobMatchResult,
            adhNamePhoneticScore,
            facematchResult,
            livenessResult,
            adhNameTextScore,
            nameOnUpi,
            upiNamePhoneticScore,
            upiNameTextScore,
            isUpiAutoPay
        } = details ? details : {};

        const appliedOnAgent = (age) => {
            const tempAge = age.split("@");
            const tempName = tempAge[0].split(".")
            if (tempName[1]) {
                return tempName[0] + " " + tempName[1]
            } else {
                return tempName[0]
            }

        }
        const tempData = [
            { key: "rw-loan-id", label: "APPLICATION ID", value: loanId ?? "-" },
            { key: "rw-bank-name", label: "BANK NAME", value: bankName ?? "-" },
            // { key: "rw-bank-verified", label: "BANK VERIFIED", value: bankVerified ? "YES" : "NO" },
            { key: "rw-bank-account-no", label: "BANK ACCOUNT NUMBER", value: bankAccountNumber ?? "-" },
            { key: "rw-ifsc", label: "IFSC", value: ifsc ?? "-" },
            // { key: "rw-name-match", label: "NAME MATCH", value: nameMatch ? "YES" : "NO" },
            { key: "rw-name-on-bank", label: "NAME ON BANK", value: nameOnBank ?? "-" },
            { key: "rw-name-on-pan", label: "NAME ON PAN", value: nameOnPan ?? "-" },
            { key: "loan-rw-name-on-aadhaar", label: "Name on Aadhaar", value: nameOnAadhaar ?? "-" },
            { key: "loan-rw-name-from-emp-", label: "Name from Employer", value: nameFromEmployer ?? "-" },
            { key: "loan-rw-dob-aadhaar", label: "DOB on Aadhaar", value: dobAadhaar ?? "-" },
            { key: "loan-rw-liveness-score", label: "Liveness Score", value: livenessScore ?? "-" },
            { key: "loan-rw-dob-on-aadhaar", label: "DOB Aadhaar", value: dobAadhaar ?? "-" },
            { key: "loan-rw-dob-user-entered", label: "DOB by User", value: dobUserEntered ?? "-" },
            { key: "loan-rw-facematch-aadhaar-selfie-score", label: "Facematch Aadhaar Selfie Score", value: faceMatchAadharSelfieScore ?? "-" },
            { key: "loan-rw-dob-pan", label: "DOB on PAN", value: dobPan ?? "-" },
            { key: "loan-rw-emp-name-phonetic-score", label: "Name Phonetic Score", value: empNamePhoneticScore ?? "-" },
            { key: "loan-rw-emp-name-text-score", label: "Name Text Score", value: empNameTextScore ?? "-" },
            { key: "loan-rw-name-match-result", label: "Name Match Result", value: nameMatchResult ?? "-" },
            { key: "loan-rw-bank-name-text-score", label: "Bank Name Text Score", value: bankNameTextScore ?? "-" },
            { key: "loan-rw-aadhaar-name-text-score", label: "Aadhaar Name Text Score", value: adhNameTextScore ?? "-" },
            { key: "loan-rw-final-result", label: "Final Result", value: finalResult ?? "-" },
            { key: "loan-rw-bank-name-phonetic-score", label: "Bank Name Phonetic Score", value: bankNamePhoneticScore ?? "-" },
            { key: "loan-rw-dob-match-result", label: "DOB Match Result", value: dobMatchResult ?? "-" },
            { key: "loan-rw-adh-name-phonetic-score", label: "Aadhaar Name Phonetic Score", value: adhNamePhoneticScore ?? "-" },
            { key: "loan-rw-face-match-result", label: "Facematch Result", value: facematchResult ?? "-" },
            { key: "loan-rw-liveness-result", label: "Liveness Result", value: livenessResult ?? "-" },
            // { key: "rw-bank-account-dedup", label: "BANK ACCOUNT DEDUP", value: bankAccountDedup ? "YES" : "NO" },
            // { key: "rw-pan-dedup", label: "PAN DEDUP", value: panDedup ? "YES" : "NO" },
            { key: "rw-reviewed", label: "REVIEWED", value: reviewed ? "YES" : "NO" },
            { key: "rw-agent", label: "AGENT", value: agent ? appliedOnAgent(agent) : "-" },
            { key: "rw-name-verification-doc-upload-date", label: "NAME VARIFICATION DATE", value: convertTimestamptoDateWithTime(nameVerificationDocUploadDate) },
            { key: "rw-last-updated", label: "LAST UPDATED", value: convertTimestamptoDateWithTime(lastUpdated) },
            { key: "rw-comment", label: "COMMENT", value: comment ?? "-" },
            { key: "rw-last-action-date", label: "LAST ACTION DATE", value: convertTimestamptoDateWithTime(lastActionDate) },
            { key: "rw-last-action", label: "LAST ACTION", value: lastAction ?? "-" },
        ]
        const tempDataImg = [
            { key: "rw-doc-location", label: "DOCUMENT", value: docLocation ?? "-" },
        ]
        return (<>

            <div style={styles.detailView}>
                <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                    <Typography variant="h6" gutterBottom style={styles.header}>DETAILS</Typography>
                    {this.makeRow(tempData)}

                </Grid>
                <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                    <Typography variant="h6" gutterBottom style={styles.header}>DOCUMENT</Typography>
                    {this.makeImageRow(tempDataImg)}
                </Grid>
            </div>
            <CustomModal
                isOpen={modalOpen}
                handleClose={this.handleModal}
            >{<>
                {modalChildren}
            </>}</CustomModal>

        </>)
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    checkPermission = () => {
        let hasTrue = false;
        const { role } = getLocalStorage("LmsUserDetails");
        const roleAdd = role;
        for (let item of roleAdd) {
            if (["ROLE_CRM_ADMIN", "ROLE_UW", "ROLE_MRRW", "ROLE_ADMIN_RO"].includes(item)) {
                hasTrue = true;
                break;
            }
        }
        return hasTrue;
    }


    render () {
        const { hasError, errorType, errorMessage, actionType, actionDialogBoxIsOpen, showButton } = this.state;
        return (
            <>
                <div style={styles.rootContainer}>
                    <div style={styles.backBar}>
                        <Button
                            variant="contained"
                            color="default"
                            startIcon={<ArrowBackIcon />}
                            onClick={this.handleBack}
                            style={styles.backIcon}
                        >
                            Back
                        </Button>
                    </div>
                    {this.showDetails()}
                    {showButton && this.checkPermission() ?
                        <div className="mybutton">
                            <Button
                                variant="contained"
                                color="default"
                                className="feedback"
                                style={{ backgroundColor: "green", margin: 10, color: "white" }}
                                startIcon={<DoneIcon />}
                                onClick={() => {
                                    this.dialogBoxHandleOpen("approve")
                                }}
                            >
                                Approve
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                className="feedback"
                                style={{ backgroundColor: "red", margin: 10, color: "white" }}
                                startIcon={<ClearIcon />}
                                onClick={() => {
                                    this.dialogBoxHandleOpen("reject")
                                }}
                            >
                                Reject
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                className="feedback"
                                style={{ backgroundColor: "#85C9E8", margin: 10, color: "white" }}
                                startIcon={<PublishIcon />}
                                onClick={() => {
                                    this.dialogBoxHandleOpen("reUpload")
                                }}
                            >
                                Reupload
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                className="feedback"
                                style={{ backgroundColor: "orange", margin: 10, color: "white" }}
                                startIcon={<ClearIcon />}
                                onClick={() => {
                                    this.dialogBoxHandleOpen("reset")
                                }}
                            >
                                Reset
                            </Button>
                        </div> : null}

                    {actionDialogBoxIsOpen ? this.setDialogBox(actionType) : null}

                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    imgStyle: {
        height: 25,
        width: 25,
        margin: "10px 15px 10px 20px"
    },
    textStyle: {
        marginTop: 14
    },
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        padding: 20,
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    btnContainerAccept: {
        position: 'absolute',
        bottom: "22%",
        right: ".5%",
        background: "#1B2D93",
        color: "white",
        margin: 20
    },
    btnContainerReject: {
        position: 'absolute',
        bottom: "14%",
        right: ".5%",
        background: "red",
        color: "white",
        margin: 20
    },
    btnContainerReupload: {
        position: 'absolute',
        bottom: "6%",
        right: ".5%",
        background: "#0085ad",
        color: "white",
        margin: 20,
    },
    detailHover: {
        position: 'absolute',
        top: "15%",
        right: "0.1%",
        background: "white",
        color: "black",
        margin: 10,
    },
    elseBtn: {
        position: 'absolute',
        top: "15.5%",
        right: "9.5%",
        background: "transparent",
        color: "black",
        padding: 10,
        margin: 20,
        width: "auto",
        heigth: 10,
        borderRadius: 10
    },
    backBar: {
        position: 'absolute',
        marginLeft: -10,
    },
    backIcon: {
        backgroundColor: "rgb(236,246,255)",
        color: "black",
        borderRadius: 5,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    fieldLabel: {
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E"
    },
    fieldValue: {
        fontSize: 17,
        fontFamily: "inherit",
        whiteSpace: 'break-spaces'
    },
    container: {
        padding: "0 60px"
    },
    header: {
        marginTop: 50,
    },
    detailView: {
        // flowGrow: 1,
        // background: "#fff",
        // padding: 20,
        // height: "calc(100vh - 180px)",
        // overflowY: "scroll"
    }
}

export default ManualCheckDetail;