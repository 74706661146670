import { useState, useEffect, useRef } from 'react'
import { Colors, Fonts } from '../../styles'
import { DailyIncomeService } from '../../service'
import  { Container, TextLabel, Separator } from '.'
import UploadProgress from './upload-progress'
import UploadComplete from './upload-complete'
import { Function } from '../../utils'
import { Alert, Loader } from '../../components'

export default function UploadFile({ companyId, updateFileValidationStatus, uploadProgress, abortUpload, uploadedCSV }) {
	const [validationStatus, setValidationStatus] = useState({ successCount: 0, errorCount: 0, fileBase64: null })
	const [error, setError] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [loading, setLoading] = useState(false)
	const [fileData, setFileData] = useState({ size: 0, name: "" })

	useEffect(() => {
		if (uploadProgress === 0) {
			resetFileValidationStatus();
		}
	}, [uploadProgress])

	const resetFileValidationStatus = () => {
		setValidationStatus({ successCount: 0, errorCount: 0, fileBase64: null })
	}

	const onValidateFile = (event) => {
		const file = event.target.files[0]
		if (!file) {
			resetFileValidationStatus()
			return;
		}
		if (!companyId) {
      alert("Company not selected. Please select a company first.")
      return;
    }
		if (file.size > 5000000) {
			setErrorMessage("File exceeds size limit")
			setError(true)
			return;
		}
		setFileData(file)
		setLoading(true)
		const formData = new FormData()
		formData.append("file", file)
		formData.append('orgId', companyId)
		setError(false)
		DailyIncomeService.dailyIncomeFileValidate(formData, (result) => {
			console.log('dailyIncomeFileValidate', result);
			setLoading(false)
			if (result.status) {
				setValidationStatus(result.data)
				const {errorCount, fileBase64, successCount} = result.data;
				if (!errorCount && successCount > 0 && fileBase64) {
						updateFileValidationStatus(file)
						return
				}
				updateFileValidationStatus()
			} else if (result.errorCode in ERROR_CODES) {
				setError(true)
				setErrorMessage(ERROR_CODES[result.errorCode])
				resetFileValidationStatus()
				updateFileValidationStatus()
			} else {
				setError(true)
				setErrorMessage("")
				resetFileValidationStatus()
				updateFileValidationStatus()
			}
		})
	}
	const {errorCount, fileBase64, successCount} = validationStatus;
	return (
		<Container>
			<div
				style={{
					fontSize: Fonts.size.medium,
					fontWeight: Fonts.weight.medium,
				}}
			>
				Upload File
			</div>
			<Separator />
			<UploadFileContainer
				abortUpload={abortUpload}
				companyId={companyId}
				onChange={onValidateFile}
				uploadProgress={uploadProgress}
				uploadedCSV={uploadedCSV}
				fileData={fileData} />
			<SupportedFiles />
			{fileBase64 && <UploadResult
				totalProcessed={successCount + errorCount}
				success={successCount}
				failed={errorCount} />}
			<FailedCasesLink errorCount={errorCount} base64Data={fileBase64} />
			<Alert
				open={error}
				message={errorMessage}
				callback={() => setError(false)}
			/>
			{loading && <Loader />}
		</Container>
	)
}

const UploadFileContainer = ({ children, onChange, uploadProgress, fileData, abortUpload, companyId, uploadedCSV }) => (
	<div
		style={{
			border: `1px dashed ${Colors.typographyColor06}`,
			borderRadius: 4,
			padding: '20px',
			marginTop: 24,
		}}
	>
		<div
			style={{
				marginTop: 24,
				textAlign: 'center',
				fontWeight: Fonts.weight.medium,
			}}
		>
			Click here to upload document
		</div>
		<ChooseFile onChange={onChange} companyId={companyId} />
		<UploadProgress uploadProgress={uploadProgress} abortUpload={abortUpload} />
		<UploadComplete fileData={fileData} uploadComplete={uploadedCSV} />
	</div>
)

const ChooseFile = ({ onChange, companyId }) => {
	// const [file, setFile] = useState(null)
	// const _onChange = (e) => {
	// 	setFile(e)
	// 	onChange(e)
	// }
	const resetUploadedDocument = (event)=> {
		event.target.value = null;
	}

	return (
		<input
			disabled={!companyId}
			onChange={onChange}
			onClick={resetUploadedDocument}
			type="file"
			accept=".csv"
			style={{
				padding: '8px 20px',
				borderRadius: 8,
				margin: '16px 32%',
				border: `1px solid ${companyId ? Colors.supportingColorInProgress01: Colors.typographyColor02}`,
				color: companyId ? Colors.supportingColorInProgress01 : Colors.typographyColor02,
			}}
		/>
	)
}

const FailedCasesLink = ({ errorCount, base64Data = null }) => {
	const downloadFile = () => {
		Function.downloadCSV(base64Data, "failed-cases.csv")
	}
	if (errorCount && base64Data) {
		return (
			<div onClick={downloadFile} style={{
				fontWeight: Fonts.weight.medium,
				marginTop: 12,
				cursor: 'pointer',
				textAlign: 'center',
				color: Colors.supportingColorInProgress01
			}}>Download failed cases</div>
		)
	} return null;
}

const UploadResult = ({ totalProcessed, success, failed }) => {
	return (
		<>
			<div
				style={{
					fontSize: 12,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					margin: '12px 0',
				}}
			>
				<span
					style={{
						textAlign: 'center',
					}}
				>
					{totalProcessed} Cases Processed | {success} Successful |{' '}
					<span
						style={{
              fontWeight: failed > 0 ? '600': '400',
							color:
								failed > 0
									? Colors.semanticColorFailed01
									: Colors.typographyColor01,
						}}
					>
						{failed} Failed
					</span>
				</span>
			</div>
		</>
	)
}

const SupportedFiles = () => (
	<div
		style={{
			textAlign: 'center',
			marginTop: 12,
			color: Colors.typographyColor02,
			fontSize: 12,
		}}
	>
		Supported files: <b>CSV</b> | Max file size: <b>5 MB</b>
	</div>
)

const ERROR_CODES = {
	EE00073: "Invalid Header",
  EEE00074: "Header mismatch at column 0: Expected PARTNERID",
  EEE00075: "Header mismatch at column 1: Expected NAME",
  EEE00076: "Header mismatch at column 2: Expected PAN",
  EEE00077: "Header mismatch at column 3: Expected PHONE",
  EEE00078: "Header mismatch at column 4: Expected LOCATION",
  EEE00079: "Future date found",
  EEE00080: "Invalid date format ",
  EEE00081: "Error reading the file",
	EEE00082: "Unexpected error",
	EEE00084: "Header mismatch at column 5: Expected date in format dd-yy-mm or dd/mm/yyyy."
}