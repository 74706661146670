export default {
  primary: 'rgb(21, 27, 128)',
  white: 'rgba(255, 255, 255, 1)',
	black: 'rgba(0, 0, 0, 0)',
	primaryColor01: 'rgba(27, 45, 147, 1)',
	primaryColor02: 'rgba(187, 192, 223, 1)',
	primaryColor05: 'rgba(240, 248, 255, 1)',
	primaryColor04: 'rgba(232, 234, 244, 1)',
	primaryColor06: 'rgba(248, 249, 252, 1)',
	typographyColor01: 'rgba(18, 18, 18, 1)',
	typographyColor02: 'rgba(89, 89, 89, 1)',
	typographyColor03: 'rgba(137, 137, 137, 1)',
	typographyColor04: 'rgba(184, 184, 184, 1)',
	typographyColor05: 'rgba(208, 208, 208, 1)',
	typographyColor06: 'rgba(231, 231, 231, 1)',
	typographyColor07: 'rgba(255, 255, 255, 1)',
	semanticColorSuccess01: 'rgba(42, 143, 83, 1)',
	semanticColorSuccess02: 'rgba(234, 244, 238, 1)',
	supportingColorInProgress01: 'rgba(17, 102, 238, 1)',
	supportingColorInProgress02: 'rgba(231, 240, 253, 1)',
	semanticColorFailed01: 'rgba(246, 55, 93, 1)',
	semanticColorFailed02: 'rgba(255, 235, 239, 1)',
	semanticColorWarning01: 'rgba(240, 136, 4, 1)',
	semanticColorWarning02: 'rgba(255, 245, 233, 1)'
}