import { useState, useEffect, useRef } from 'react'
import { Colors, Fonts } from '../../styles'
import { CancelOutlined } from '@material-ui/icons'

export default function UploadProgress({ uploadProgress, abortUpload }) {
	console.log('uploadProgress', uploadProgress)
	if (uploadProgress > 0 && uploadProgress <= 99) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div style={{ width: '100%' }}>
					<div
						style={{
							fontSize: 12,
							fontWeight: '600',
							marginTop: 10,
						}}
					>
						Uploading item
					</div>
					<UploadPercentage percentageCompleted={uploadProgress} timeLeft={""} />
					<ProgressIndicator percentageCompleted={uploadProgress} />
				</div>
				<div onClick={abortUpload}>
					<CancelOutlined style={{ color: Colors.typographyColor03, cursor: 'pointer' }} />
				</div>
			</div>
		)
	}
	return null;
}

const ProgressIndicator = ({ percentageCompleted }) => {
	return (
		<progress
			style={{ marginTop: 10 }}
			value={percentageCompleted}
			max="100"
		>
			{' '}
			{percentageCompleted}%{' '}
		</progress>
	)
}

const UploadPercentage = ({ percentageCompleted, timeLeft }) => {
	return (
		<div
			style={{
				color: Colors.typographyColor02,
				fontSize: 12,
				marginTop: 4,
			}}
		>
			{percentageCompleted}% | {timeLeft} seconds remaining
		</div>
	)
}
